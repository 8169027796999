import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartnerService } from '../partner.service';

@Component({
  selector: 'app-rejectpopup',
  templateUrl: './rejectpopup.component.html',
  styleUrls: ['./rejectpopup.component.scss']
})
export class RejectpopupComponent implements OnInit {
  remark: any;
  constructor(
    private dialogRef: MatDialogRef<RejectpopupComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private partnerService : PartnerService
  ) { }

  ngOnInit() {
    this.remark = null;
  }

  closePopup() {
    this.dialogRef.close(0);
  }

  handleAction() {
    if (this.remark && this.remark.length > 0) {
      this.partnerService.setRemark(this.remark.trim());
      this.dialogRef.close(this.data.action);
    }
  }
}
