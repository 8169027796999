import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { urls } from 'src/app/url';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  BaseUrl = environment.BASEURL;
  OnBoardingUrl = environment.ONBOARDDATA;
  currentRemark: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient) { }

  getPartenersList(obj) {
    const url = this.OnBoardingUrl + urls.getPartnersList;
    return this.http.post(url,obj);
  }

  getActionOnPartner(obj){
    const url = this.OnBoardingUrl + urls.getActionOnPartner;
    return this.http.post(url,obj);
  }
  setRemark(remark) {
    this.currentRemark.emit(remark);
  }
}
