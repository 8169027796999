<div id="popup" class="popup">
  <div class="popup-content">
    <h2>Remark</h2>
    <div class="remark-container">
      <textarea id="remark" placeholder="Enter your remarks here..." [(ngModel)]="remark"></textarea>
    </div>
    <div class="popup-actions">
      <button *ngIf="data.action == 'Reject'"class="btn reject-btn" (click)="handleAction()" [disabled]="!remark || remark.trim().length == 0">{{data.action}}</button>
      <button *ngIf="data.action == 'Accept'" class="btn accept-btn" (click)="handleAction()"[disabled]="!remark || remark.trim().length == 0">{{data.action}}</button>
      
      <button class="btn cancel-btn" (click)="closePopup()">Cancel</button>
    </div>
  </div>