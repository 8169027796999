<div class="main-container">
  <div class="header">
  <div class="filters">
    <div class="filter">
      <label for="name-select">Filter by Status</label>
      <mat-select id="name-select" placeholder="Select Status"  (selectionChange)="applyFilter($event.value)">
        <mat-option *ngFor="let status of statusList" [value]="status">
            {{ status.label }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  </div>

  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name ? element.name : '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email ? element.email : '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Phone No.</th>
        <td mat-cell *matCellDef="let element">{{ element.phoneNumber ? element.phoneNumber : '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef>Company Name</th>
        <td mat-cell *matCellDef="let element">{{ element.companyName ? element.companyName : '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef>country</th>
        <td mat-cell *matCellDef="let element">{{ element.country ? element.country : '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>Timestamp</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt ? (element.createdAt | date: 'dd MMM yyyy | HH : mm a') : '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="remark">
        <th mat-header-cell *matHeaderCellDef>Remark</th>
        <td mat-cell *matCellDef="let element">
          <span class="truncate" [matTooltip]="element.remarks">{{ element.remarks ? element.remarks : '-' }}</span></td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <span class="status" [ngClass]="{
            'pending': element.status === 'PENDING',
            'approved': element.status === 'APPROVED',
            'rejected': element.status === 'REJECTED'
          }">
            {{ element.status }} 
          </span>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.status === 'PENDING'">
            <button mat-button class="action-button accept" (click)="openAction(element,'Accept')">Accept</button>
            <button mat-button class="action-button reject" (click)="openAction(element,'Reject')">Reject</button>
          </span>
          <span *ngIf="element.status != 'PENDING'">
              --
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
<mat-paginator [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20,50]"
  (page)="getPaginatorData($event)">
</mat-paginator>

</div>