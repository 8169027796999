export const environment = {
	BASEURL: 'https://apiqa.credlixlab.com/',
	credlixURL: 'https://uiqa.credlixlab.com',
	ESCROWURL: 'https://payuat.credlixlab.com/',
	EXIMAUTOSALES: 'https://exim-auto-sale.credlixlab.com/',
	UploadMappingURL: 'https://credlix-data.integration.credlixlab.com/',
	CREDLIXKANBAN: 'https://credlix-kanban-qa.credlixlab.com/',
	ONBOARDDATA: 'https://credlix-onboard-data-qa.credlixlab.com/',
	EsSEARCH: 'https://qa-es-index-service.credlixlab.com/',
	MOGLIXKANBAN: 'https://moglix-kanban.credlixlab.com/',
	//credlixSID:"https://credlix-sid.credlixlab.com/",
	// MOGLIXKANBAN: "https://moglix-kanban.credlixlab.com/",
	CREDLIXSIDJOURNEY: 'https://credlix-onboard-data-qa.credlixlab.com/',
	CREDLIXANCHOR: 'https://credlix-anchor.credlixlab.com/',
	bussinessBaseUrl: 'https://credlix-business-backend.credlixlab.com/',
	JK_FENNER_ANCHOR_ID: '65fbdd852d8bec3eaec21fb9', //TODO: change this id if of in UAT environment
	CAPTCH_SITE_KEY: '6LdgoIUqAAAAANPSQNdncrDo9dmX5tziDfWGy04N',
	production: false,
}
