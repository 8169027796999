import { Injectable } from '@angular/core';
import { params } from '../param';
import { urls } from '../url';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient) { }
  readonly BaseUrl = environment.BASEURL;

  offSpinner() {
    const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
    const curtain = <HTMLDivElement>document.getElementById('curtain');
    spinner.style.display = 'none';
    curtain.style.display = 'none';
  }
  userRegister(obj) {
    let url = this.BaseUrl + urls.signUpVedantaUser;
    return this.http.post(url, obj);
  }
  useLogin(obj, captchaToken) {
    let header = {
      "g-recaptcha-response": captchaToken
    }
    let url = this.BaseUrl + urls.userSignIN
    return this.http.post(url, obj, { headers: header });
  }
  userVerifcation(obj) {
    let url = this.BaseUrl + urls.userVerification + '?mobileNumber=' + obj.mobileNumber
    return this.http.post(url, {});
  }

  verifyOTP(obj1) {
    let url = this.BaseUrl + urls.verifyOTP
    return this.http.post(url, obj1);
  }

  userAccountSetUp(obj) {
    let url = this.BaseUrl + urls.accountSetUp
    return this.http.put(url, obj);
  }
  EmailValidation(obj) {
    let url = this.BaseUrl + urls.checkEmailExist + '?email=' + obj.email
    return this.http.get(url, obj);
  }
  getIndustryName() {
    let url = this.BaseUrl + urls.industryList
    return this.http.get(url);
  }

  changePassword(obj) {
    let url = this.BaseUrl + urls.changePassword
    return this.http.post(url, obj);
  }

  forgotPassword(obj) {
    let url = this.BaseUrl + urls.forgotPassword
    return this.http.post(url, obj);
  }

  resetPassword(obj) {
    let url = this.BaseUrl + urls.resetPassword
    return this.http.post(url, obj);
  }

  private currentUserSubject = new BehaviorSubject<any>([]);
  public currentUser = this.currentUserSubject.asObservable()
  //session storage
  getUserDetails(data) {

    localStorage.setItem("USERINFO", JSON.stringify(data));
    this.currentUserSubject.next(data)
    let roleType: string;
    if (data['role'] != null) {
      roleType = data.role.role.roleType;
      if(data['role']['masterAccount']['isSid'] && data['role']['role']['roleType']== 'SID'){
        localStorage.setItem('credlixUserID', data['role']['masterAccount']['id'])
      }
    }
    if (roleType == "ANCHOR") {
      localStorage.setItem("isCustomer", 'true');
    }
    // if (data.role.businessAccounts != null && data.role.businessAccounts != '') {
    //   this.storageBusinessID(JSON.stringify(data.role['businessAccounts'][0]));
    // }
  }

  private loginVedantaToken = new BehaviorSubject<any>([]);
  public loginTokenBeh = this.loginVedantaToken.asObservable()

  storeVedantaToken(token) {
    this.loginVedantaToken.next(token);
  }
  isAccordUser() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['moglix'] != null && userInfo['moglix'] == false) {
      return true
    }
    else {
      return false
    }
  }

  // storageBusinessID(ID) {
  //   localStorage.setItem("businessID", ID);
  // }


  createSession(token) {
    localStorage.setItem('TOKEN', token);
  }
  getSessonStorage() {
    if (localStorage.getItem('TOKEN')) {
      return true;
    }
  }
  storageUserDetails(user) {
    localStorage.setItem('currentUser', user);
  }

  getCurrentUser() {
    if (localStorage.getItem('currentUser')) {
      return true;
    }
  }
  getIsCusotmer() {
    return localStorage.getItem('isCustomer') == 'true' ? true : false;
  }

  getIsFinanceUser() {
    return localStorage.getItem('isFinanceUser') == 'true' ? true : false;
  }
  getIsSupplyUser() {
    return localStorage.getItem('isSupplyUser') == 'true' ? true : false;
  }


  storageAccountName(accountname) {
    localStorage.setItem('accountName', accountname);
    const regex = /@moglix.com/gm;
    let isMoglixUser = false;
    if (accountname != null) {
      if (accountname == 'finance@moglix.com') {
        localStorage.setItem('isFinanceUser', 'true');
      }
      if (accountname == 'supply@moglix.com') {
        localStorage.setItem('isSupplyUser', 'true');
      }

      // isMoglixUser = regex.test(accountname);
    }
    // localStorage.setItem('isCustomer', isMoglixUser.toString());
  }
  getStorageAccountName() {
    if (localStorage.getItem('accountName')) {
      return true;
    }
  }



  customAuthentication(obj) {
    let url = this.BaseUrl + urls.customAuthentication
    return this.http.post(url, obj);
  }
  getUserByToken(obj) {
    let url = this.BaseUrl + urls.getUserByToken
    return this.http.post(url, obj);
  }

  setIsMoglixUser(user) {
    //localStorage.setItem('isMoglixUser', isMoglixUser);
  }
  destroySession() {
    localStorage.clear();
  }

  storageListHeaders(headerData) {
    localStorage.setItem('importConfig', JSON.stringify(headerData));
    // localStorage.setItem('borrowerDisplayConfig', JSON.stringify(headerData['borrowerDisplayConfig']));
    // localStorage.setItem('invoiceDisplayConfig', JSON.stringify(headerData['invoiceDisplayConfig']));
    // localStorage.setItem('loanInvoiceConfig', JSON.stringify(headerData['loanInvoiceConfig']));
  }

  isChannleFinancing() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['nuphiUser'] == false && userInfo['channleFinancing'] == true) {
      return true
    }
    else {
      return false
    }
  }

  isLenderLeads() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "LENDER") {
      return true;
    }
  }

  isSIDLenderLogin() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['masterAccount']['isSid'] && userInfo['role']['roleType'] == "LENDER") {
      return true;
    }
  }

  isEPfinancing() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['nuphiUser'] == false && userInfo['epfinancing'] == true) {
      return true
    }
    else {
      return false
    }
  }

  isAnchor() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "ANCHOR") {
      return true;
    }
  }

  isVedantaUserExist(obj) {
    let url = this.BaseUrl + urls.checkVedantaUser + "/" + obj.token
    return this.http.post(url, obj);
  }

  fetchVedantaUserDetails(obj) {
    let url = this.BaseUrl + urls.vedantaUserDetails + "/" + obj.token
    return this.http.get(url);
  }

  isCredlixUser() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && (userInfo['role']['role']['roleType'] == "CREDLIX" || userInfo['role']['role']['roleType'] == "AF_CREDLIX")) {
      return true;
    }
    return false;
  }

  isAnchorFinancing() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['masterAccount']['isAnchorFinancing']) {
      return true;
    }
    return false;
  }

  isSIDUSER() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "SID") {
      return true;
    }
    return false;
  }
  isKanbanUser() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['roleType'] == "MOGLIX") {
      return true;
    }
    return false;
  }

  isBuyer() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "BUYER") {
      return true;
    }
    return false;
  }

  getAnchorDisplayName(){
    let anchorDisplayName;
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    anchorDisplayName = getSessionStorage['accountName'];
    if (getSessionStorage['credlixAnchorList']) {
      getSessionStorage['credlixAnchorList'].map(val => {
        if (val.id == localStorage.getItem("credlixUserID")) {
          anchorDisplayName = val.displayName;
        }
      })
    }
    return anchorDisplayName;
  }
  isBorrower() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "BORROWER") {
      return true;
    }
    return false;
  }

  isSIDRoleType() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['masterAccount']['isSid']) {
      return true;
    }
    return false;
  }
  isCompanyAdmin() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['roleName'] == "COMPANY_ADMIN") {
      return true;
    }
    return false;
  }
  isPartnerProgrmaViewAccess() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['roleName'] == 'PARTNER_VIEW' && userInfo['role']['roleType'] == "CREDLIX") {
      return true;
    }
    return false;
  }

  isPartnerProgrmaAdminAccess(){
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['roleName'] == 'PARTNER_ADMIN' && userInfo['role']['roleType'] == "CREDLIX") {
      return true;
    }
    return false;
  }

  isShowApprovedBuyer() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && ((userInfo['role']['roleName'] == "COMPANY_ADMIN") && (userInfo['role']['roleType'] == 'CREDLIX'))) {
      return true;
    }
    return false;
  }
  isSalesLOSUser(){
    let userInfo = localStorage.getItem('USERINFO');
    let permissions = JSON.parse(userInfo).role.roleName;
    return (permissions == "SALES LOS");
  }

  isJKFennerAnchor(){
    const userInfo = localStorage.getItem("USERINFO");
    const credlixUserId = localStorage.getItem("credlixUserID");
    const jkFennerAnchorId = environment.JK_FENNER_ANCHOR_ID;
    if(userInfo || credlixUserId){
      if(jkFennerAnchorId === credlixUserId){
        return true;
      }
      try{
        const userInfoObj = JSON.parse(userInfo);
        const anchorAccountId = userInfoObj.role ? userInfoObj.role.anchorMasterAccountId : "";
        if(jkFennerAnchorId === anchorAccountId){
          return true;
        }
      }catch(e){
        console.log("Exception occured during user info object parsing");
      }
    }
    // TODO: put this id in environment file so that we don't have to hardcode it
    return false;
  }
  isCredlixAnchor() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "AF_CREDLIX") {
      return true;
    }
    return false;
  }
  isAnchorFinanceLender() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "AF_LENDER") {
      return true;
    }
    return false;
  }
  isAnchorFinanceAnchor() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "AF_ANCHOR") {
      return true;
    }
    return false;
  }
  isAnchorFinanceBorrower() {
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "AF_BORROWER") {
      return true;
    }
    return false;
  }
  isAnchorFinanceSales(){
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && userInfo['role']['role']['roleType'] == "AF_LENDER_SALES") {
      return true;
    }
    return false;
  }
  isWaareeAnchor(){
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && this.isAnchorFinanceAnchor()) {
      return true;
    }
    return false;
  }

  isWaareSupplier(){
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && this.isAnchorFinancing() && userInfo['afAnchor'] == 'WAAREE' && this.isAnchorFinanceBorrower()) {
      return true;
    }
    return false;
  }

  isWaareeLender(){
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && this.isAnchorFinancing() && userInfo['afAnchor'] == 'WAAREE' && this.isAnchorFinanceLender()) {
      return true;
    }
    return false;
  }
  isWaareeSalesUser(){
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    if (userInfo != null && userInfo['role'] != null && this.isAnchorFinancing() && userInfo['afAnchor'] == 'WAAREE' && this.isAnchorFinanceSales()) {
      return true;
    }
    return false;
  }
}
